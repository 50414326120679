import {useSelector} from 'react-redux';
import parse from 'html-react-parser';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  upperSectionContainer: {
    paddingTop: 24,
    padding: 50,
    maxWidth: 1140,
    alignItems: 'center',
    color: '#fff',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logoShareContainer: {
    height: '110px',
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: 64,
    },
    [theme.breakpoints.up('sm')]: {
      height: 80,
    },
  },
  title: {
    //width: 550,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 48,
    },
    fontWeight: 600,
  },
  introText: {
    marginTop: 30,
    maxWidth: 535,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      maxWidth: 300,
      marginBottom: 220,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      marginBottom: 200,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      marginBottom: 0,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
      marginBottom: 0,
    },
  },
  button: {
    marginTop: 30,
    marginBottom: 40,
    backgroundColor: '#0066A8',
    color: '#fff',
    padding: theme.spacing(3),
    textTransform: 'none',
    '&:hover':{
      backgroundColor: '#004477'
    }
  },
  womanThinkingImage: {
    position: 'absolute',
    zIndex: 0,
    background: "url('/assets/womanThinkingBoard.svg')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 250,
      top: 360,
      right: 'calc(40% - 100px)',
    },
    [theme.breakpoints.up('sm')]: {
      width: 250,
      height: 250,
      top: 390,
      right: 'calc(30% - 100px)',
    },
    [theme.breakpoints.up('md')]: {
      width: 350,
      height: 350,
      top: 200,
      right: 'calc(20% - 100px)',
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 400,
      top: 190,
      right: 'calc(50% - 500px)',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',    
    height: 100,
    [theme.breakpoints.down('md')]: {
      marginBottom: 240,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 140,
    },
    flex: '1 1 0px',
  },
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    width: 280,
    [theme.breakpoints.down('md')]: {
      height: 212,
    },
    [theme.breakpoints.up('lg')]: {
      height: 100,
    },
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 1,
  },
  cardHorizontalAlign: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      justifyContent: "center"
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: "row",
    },
    height: '90%',
  },
  cardText: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cardImage: {
    height: 48,
    width: 48,
    [theme.breakpoints.up('lg')]: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  }
}));

function Card(props){
  const classes = useStyles();

  return (
    <Paper elevation={4} className={classes.card} >
      <div className={classes.cardHorizontalAlign}>
        <img src={props.source} className={classes.cardImage} alt="bullet point" />
        <Typography className={classes.cardText}>
          {parse(props.text.replace('<p>', '').replace('</p>', ''))}
        </Typography>
      </div>
    </Paper>
  )
}

function UpperSection() {
  const name = useSelector(state => state.data.name);
  const intro = useSelector(state => state.data.introduction);
  const start_button_text = useSelector(state => state.data.start_button_text);
  const step1 = useSelector(state => state.data.step1);
  const step2 = useSelector(state => state.data.step2);
  const step3 = useSelector(state => state.data.step3);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <div className={classes.upperSectionContainer}>
        <div className={classes.logoShareContainer}>
          <a  href="http://thinkbluehat.com/" target="_blank" rel="noreferrer" style={{cursor: "pointer"}}>
            {/* <img src='https://bht.tallhatdev.co.uk/wp-content/uploads/2022/06/bluehat-logo.svg' alt="BlueHatLogo" className={classes.logo}/> */}
            <img src='BHA-logo-2023.png' alt="BlueHatLogo" className={classes.logo}/>
          </a>
        </div>
        <Typography className={classes.title}>
          {name}
        </Typography>
        <Typography className={classes.introText}>
          {parse(intro.replace('<p>', '').replace('</p>', ''))}
        </Typography>
        {!mobile && (
          <Fab className={classes.button} variant="extended" href="#survey">
            {start_button_text}
          </Fab>
        )}
        <div className={classes.womanThinkingImage} role="img" alt="womanThinking" />
      </div>
        
        
      <Hidden xsDown>
        <div className={classes.cardContainer}>
          <Card key={1} source="/assets/circle1.svg" text={step1} />
          <Card key={2} source="/assets/circle2.svg" text={step2} />
          <Card key={3} source="/assets/circle3.svg" text={step3} womanThinkingImage/>
        </div>
      </Hidden>
    </div>
  );
}

export default UpperSection;
